define('modules/judges/basic/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.livequery"], function(module, config, $, util, templates) {
  return new module({
    name: 'judges.basic',
    selectors: '.judgePageWrap, .judgeSection',
    remoteData: [config.urls.judges],
    routes: [{
      route: 'judges/(.*)',
      fn: function(hash) {
        $('#judges .judgeBox[data-judge="' + hash.split('/')[1] + '"]').trigger('click');
      },
      extra: true,
      delay: 2500
    }, {
      route: 'judges/peer-to-peer',
      fn: function(hash) {
        $('.navbarWrap').addClass('nav-shrink');
        $('html, body').animate({
          scrollTop: $('.load_judges_g1_h3').offset().top - 150
        }, 500);
      },
      extra: true,
      delay: 2000
    }, {
      route: 'judges/technical-cultural-advisory-committee',
      fn: function(hash) {
        $('.navbarWrap').addClass('nav-shrink');
        $('html, body').animate({
          scrollTop: $('.load_judges_g2_h3').offset().top - 150
        }, 500);
      },
      extra: true,
      delay: 2000
    }, {
      route: 'judges/selection-committee',
      fn: function(hash) {
        $('.navbarWrap').addClass('nav-shrink');
        $('html, body').animate({
          scrollTop: $('.load_judges_g3_h3').offset().top - 150
        }, 500);
      },
      extra: true,
      delay: 2000
    }],


    config: {},

    initialize: function(data) {
      this.data = (data || this.data);
      this.renderTemplates(this.data);
      this.clickHandlers(this.data);

    },

    methods: {
      renderTemplates: function(data) {
        var judgeGroups = [],
          templateData = {
            judgeGroups: []
          };
        $.each(data, function(index, record) {
          if (!judgeGroups[record.type]) {
            judgeGroups[record.type] = {
              name: record.type,
              title: record.shortDescription,
              cms: record.cms,
              desc: record.desc,
              descTitle: record.descTitle,
              records: []
            };
          }
          judgeGroups[record.type].records.push(record);
        });
        for (var key in judgeGroups) {
          templateData.judgeGroups.push(judgeGroups[key]);
        }
        this.templateData = templateData;
        $('.judgePageWrap').append(templates.judgespage({
          data: templateData,
          config: this.config
        }));

        $('.judgeGridContainer').each(function() {
          $('.judgeBox', this).eq(0).addClass('active');
        });

        $(this.getSelectors()).find('.judge-long-description').each(function() {
          if ($(this).find('.judge-quote-container').length) {
            var foo = $(this).find('.judge-quote-container');
            $(this).find('p:eq(0)')
            $("#mydiv div:eq(0)").after

          }
        });
      },
      clickHandlers: function() {
        $(this.getSelectors()).find('.judgeBox').on('click', $.proxy(this.onJudgeBoxClick, this));
        $(this.getSelectors()).find('.judgeDetailClose').on('click', $.proxy(this.onJudgeCloseClick, this));

      },
      onJudgeCloseClick: function() {

        var moduleGetSelectors = $(this.getSelectors());

        $(this.getSelectors()).find('#judgesDetail').removeClass('judgeDetailOpen').delay(1000).queue(function(next) {
          $(this).hide();
          moduleGetSelectors.find('#judgesDetail').hide();
          moduleGetSelectors.find('.judgeDetailContainer').removeClass('active');
          moduleGetSelectors.find('.judgeInfoGroup').removeClass('active');
          moduleGetSelectors.find('.judgeInfoContainer').removeClass('active');
          moduleGetSelectors.find('.judgesBasicNav .currentCount').html('');
          $('body').removeClass('locked');
          $('.navbarWrap').removeClass('slideUpOut');
          next();
        });
      },
      onJudgeBoxClick: function(e) {
        var jGroup = $(e.delegateTarget).attr('data-judgegroup'),
          jId = $(e.delegateTarget).attr('data-judge');

        $('body').addClass('locked');
        $('.navbarWrap').addClass('slideUpOut');

        $(this.getSelectors()).find('#judgesDetail').show().delay(50).queue(function(next) {
          $(this).addClass('judgeDetailOpen');
          next();
        });

        $(this.getSelectors()).find('.judgeDetailContainer[data-judgegroup="' + jGroup + '"]').addClass('active');
        $(this.getSelectors()).find('.judgeInfoGroup[data-judgegroup="' + jGroup + '"]').addClass('active');
        $(this.getSelectors()).find('.judgeInfoContainer[data-judge="' + jId + '"]').addClass('active');

        var activeJudgeId = $(this.getSelectors()).find('.judgeInfoGroup.active .judgeInfoContainer.active').index() + 1;
        $(this.getSelectors()).find('.judgeDetailCounter .currentCount').html(activeJudgeId);

        $('.judgeGridContainer .judgeBox.active').removeClass('active');
        $(e.delegateTarget).addClass('active');

        this.judgesBasicGroupNav();
      },
      judgesBasicGroupNav: function() {
        var moduleGetSelectors = $(this.getSelectors()).find('.judgeInfoGroup.active'),
          that = $(this.getSelectors()),
          activeIndex,
          lastIndex,
          nextIndex,
          prevIndex;

        // handle jude nav in it's own module
        $(this.getSelectors()).find('.judgeInfoGroup.active .judgeDetailNav.prev, .judgeInfoGroup.active .judgeDetailNav.next').unbind('click').on('click', $.proxy(function(e) {
          activeIndex = +(moduleGetSelectors.find('.judgeDetailCounter .currentCount').html());
          nextIndex = activeIndex + 1;
          prevIndex = activeIndex - 1;
          lastIndex = +(moduleGetSelectors.find('.judgeDetailCounter .totalCount').html());

          //next at end
          if ($(e.currentTarget).hasClass('next') && (activeIndex == lastIndex)) {

            moduleGetSelectors.find('.judgeInfoContainer.active').removeClass('active');
            moduleGetSelectors.find('.judgeInfoContainer').eq(0).addClass('active');
            moduleGetSelectors.find('.judgeDetailCounter .currentCount').html('1');
            that.find('.judgeGridContainer .judgeBox.active').removeClass('active');
            that.find('.judgeGridContainer .judgeBox:first-child').addClass('active');
          }
          // next
          else if ($(e.currentTarget).hasClass('next')) {
            moduleGetSelectors.find('.judgeInfoContainer.active').removeClass('active').next('.judgeInfoContainer').addClass('active');
            moduleGetSelectors.find('.judgeDetailCounter .currentCount').html(nextIndex);
            that.find('.judgeGridContainer .judgeBox.active').removeClass('active').next().addClass('active');
          }
          // prev at end
          else if ($(e.currentTarget).hasClass('prev') && activeIndex == 1) {
            moduleGetSelectors.find('.judgeInfoContainer.active').removeClass('active');
            moduleGetSelectors.find('.judgeInfoContainer').eq(lastIndex - 1).addClass('active');
            moduleGetSelectors.find('.judgeDetailCounter .currentCount').html(lastIndex);
            that.find('.judgeGridContainer .judgeBox.active').removeClass('active');
            that.find('.judgeGridContainer .judgeBox:last-child').addClass('active');
          } else if ($(e.currentTarget).hasClass('prev')) {
            moduleGetSelectors.find('.judgeInfoContainer.active').removeClass('active').prev('.judgeInfoContainer').addClass('active');
            moduleGetSelectors.find('.judgeDetailCounter .currentCount').html(prevIndex);
            that.find('.judgeGridContainer .judgeBox.active').removeClass('active').prev().addClass('active');
          }

          $('#judgesDetail').scrollTop(0);
        }));
      }
    }
  });
});

