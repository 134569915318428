define('modules/faqs/cards/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.livequery", "app/features/expandergrid"], function (module, config, $, util, templates) {
    return new module({
        name: 'faqs.cards',
        selectors: '.faqWrap',
        remoteData: [(config.urls.faqs)],

        config: {
            innerScroll: false
        },

        initialize: function (data) {
            this.$container = $(this.getSelectors());
            this.data = (data || this.data);

            this.renderTemplates(this.data);

            if (this.getConfig().innerScroll) {
                this.innerScrollHandler(this.data);
            }
            else {
                this.clickHandlers(this.data);
            }

            this.wrapRows();

        },

        methods: {
            renderTemplates: function (data) {
                $.each(data, function (i, r) {
                    data[i].total = data.length;
                });
                $(this.getSelectors()).append(templates.faqcards({
                    records: data
                }));

                var module = this;
                $(window).resize(function () {
                    $(module.getSelectors()).find('.expanderNav i.close').trigger('click');
                    module.wrapRows();
                });

            },

            wrapRows: function () {
                var w = $(window).width();
                var divs = $(".qa-card");

                divs.unwrap();

                if (w > 768) {

                    for (var i = 0; i < divs.length; i += 3) {
                        divs.slice(i, i + 3).wrapAll('<div class="row layout-vertical-center"></div>');
                    }

                    // trp specific
                    divs.each(function () {

                        var $self = $(this),
                            $innerWrap = $self.find('.inner-wrap');

                        if (!$innerWrap.hasClass('stopOver')) {
                            $innerWrap.addClass('stopOver');
                        }
                        ;
                    });
                }

                else {
                    for (var i = 0; i < divs.length; i += 1) {
                        divs.slice(i, i + 1).wrapAll('<div class="row"></div>');
                    }

                    // trp specific
                    divs.find('.stopOver').removeClass('stopOver');
                }
                ;
            },

            getActiveColumn: function () {
                return $('.expanderGrid').find('.inner-col[data-active="true"]').first();
            },

            clickHandlers: function (data) {
                var gridCount = 0;
                $('.expanderGrid').find('.inner-col').each(function (e) {
                    gridCount++;
                });

                // minus one since zero index
                var gridCountIndex = gridCount - 1;

                // TODO this seemed better suited within the new module, however revisit
                $(this.getSelectors()).find('.inner-col').on('click', function (e) {

                    // Set the active attribute on clicked column
                    $('.expanderGrid').find('.inner-col').attr('data-active', false);

                    // append up arrow to grid border
                    $('.expanderGrid').find('.right-arrow').not($('.expanderGrid').find('.right-arrow.next')).remove();
                    $(this).attr('data-active', true).append('<i class="right-arrow"></i>');

                    // Update and move description row
                    var parentRow = $(this).parents('.row').first();
                    $('.expanderGrid').find('.detailRow .expanderContent').html($(this).find('.innerSwap').html());
                    if ($(this).width() * 2 > $('.expanderGrid').width()) {
                        $('.expanderGrid').find('.detailRow').insertAfter($(this));
                    } else {
                        $('.expanderGrid').find('.detailRow').insertAfter(parentRow);
                    }
                    $('.expanderGrid').find('.detailRow').fadeIn({
                        complete: $.proxy(function () {
                            $(this).trigger('mouseenter'); // Trigger hover for repositioning as DOM moves
                        }, this)
                    });

                    // TODO better mobile handling
                    // write util
                    if ($('.pageContainer.active').attr('data-route') != 'home') {
                        if (w < 768) {
                            $('body').addClass('locked');
                            $('.navbarWrap').addClass('hidden');
                        }
                        else {
                            $('html, body').animate({
                                scrollTop: $(this).offset().top + 550
                            }, 500);
                        }
                    }
                    else {
                        $('html, body').animate({
                            scrollTop: $(this).offset().top - 100
                        }, 500);
                    }
                });

                // nav clicks
                $(this.getSelectors()).find('.expanderNav i').on('click', $.proxy(function (e) {
                    var activeIndex = $('.expanderGrid').find('.inner-col').index($(this.getActiveColumn($(e.currentTarget).parents('.expanderGrid'))));
                    if ($(e.target).hasClass('next')) {
                        // if we are at the end of the grid items
                        // set index to -1 to land on 0 on click next
                        if (activeIndex == gridCountIndex) {
                            activeIndex = -1;
                        }
                        $('.expanderGrid').find('.inner-col').eq(activeIndex + 1).trigger('click');
                    } else if ($(e.target).hasClass('prev')) {
                        $('.expanderGrid').find('.inner-col').eq(activeIndex - 1).trigger('click');
                    }
                }, this));

                // close click
                $(this.getSelectors()).find('.expanderNav i.close').on('click', $.proxy(function (e) {
                    $('.expanderGrid').find('.right-arrow').not($('.expanderGrid').find('.right-arrow.next')).remove();
                    $('.detailRow').fadeOut(300);
                    $('.detailRow .video-id iframe').attr('src', '');
                    $('.expanderGrid').find('.inner-col').removeAttr('data-active');
                    $('body').removeClass('locked');
                    $('.navbarWrap').removeClass('hidden');
                }, this));
            },

            innerScrollHandler: function (data) {

                this.$container.on('click', '.inner-col', $.proxy(function (event) {

                    var $innerColClicked = $(event.target);

                    if ($innerColClicked.is(':not(.inner-col)')) {
                        $innerColClicked = $innerColClicked.closest('.inner-col');
                    }
                    ;

                    if (!$innerColClicked.hasClass('opened')) {

                        var previousActiveElement = this.$container.find('.inner-col.opened');

                        if (previousActiveElement.length) {

                            //previousActiveElement.find('.inner-wrap').removeClass('stopOver');
                            previousActiveElement.removeClass('opened').find('.innerSwap').stop().slideUp(600);
                        }
                        ;


                        $innerColClicked.addClass('opened').find('.innerSwap').stop().slideDown(600);
                    }
                    ;

                }, this));

                // nav clicks
                $(this.getSelectors()).find('.expanderNav i').on('click', $.proxy(function (e) {
                    var activeIndex = $('.expanderGrid').find('.inner-col').index($(this.getActiveColumn($(e.currentTarget).parents('.expanderGrid'))));
                    if ($(e.target).hasClass('next')) {
                        // if we are at the end of the grid items
                        // set index to -1 to land on 0 on click next
                        if (activeIndex == gridCountIndex) {
                            activeIndex = -1;
                        }
                        $('.expanderGrid').find('.inner-col').eq(activeIndex + 1).trigger('click');
                    } else if ($(e.target).hasClass('prev')) {
                        $('.expanderGrid').find('.inner-col').eq(activeIndex - 1).trigger('click');
                    }
                }, this));

                // close click
                $(this.getSelectors()).find('.expanderNav i.close').on('click', $.proxy(function (e) {
                    $('.expanderGrid').find('.right-arrow').not($('.expanderGrid').find('.right-arrow.next')).remove();
                    $('.detailRow').fadeOut(300);
                    $('.detailRow .video-id iframe').attr('src', '');
                    $('.expanderGrid').find('.inner-col').removeAttr('data-active');
                    $('body').removeClass('locked');
                    $('.navbarWrap').removeClass('hidden');
                }, this));
            }
        }
    });
});

